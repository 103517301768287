import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useDateTime } from "../../components/Helper/DateTime";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import axiosInstance from "../../utils/axiosInstance";
import { CustomTooltip } from "../../utils/helpers/custom-tooltip";
import style from "./style.module.scss";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import Toast from "../../components/Toast/Toast";
import { DateFormat } from "../../utils/formatDateTime";

const LocateTransactionModal = ({
  showModal,
  setShowModal,
  selectedRow,
  dataSource,
  header,
  selectedAccount,
}) => {
  const { date, presistState, setPresistState } = useDateTime();
  const location = useLocation();
  const DynamicSubMenuItem = location?.state?.subItem;
  const [notes, setNotes] = useState(
    presistState?.goBackToLocate
      ? presistState?.notes ?? []
      : selectedRow?.notes ?? []
  );
  const [noteFlag, setNoteFlag] = useState(
    presistState?.noteFlag ? true : false
  );
  const [searchQueryDSA, setSearchQueryDSA] = useState(
    presistState?.searchQuery?.dsA ?? ""
  );
  const [tempSearchDSA, setTempSearchDSA] = useState(
    presistState?.tempSearch?.dsA ?? ""
  );
  const [searchQueryDSB, setSearchQueryDSB] = useState(
    presistState?.searchQuery?.dsB ?? ""
  );
  const [tempSearchDSB, setTempSearchDSB] = useState(
    presistState?.tempSearch?.dsB ?? ""
  );
  const [searchedTransactionDSA, setSearchedTransactionDSA] = useState(
    presistState?.searchedTransaction?.dsA ?? []
  );
  const [searchedTransactionDSB, setSearchedTransactionDSB] = useState(
    presistState?.searchedTransaction?.dsB ?? []
  );
  const [selectedTransactionDSA, setSelectedTransactionDSA] = useState(
    presistState?.selectedRow?.selectedDSA ?? []
  );
  const [selectedTransactionDSB, setSelectedTransactionDSB] = useState(
    presistState?.selectedRow?.selectedDSB ?? []
  );
  const [searchedHeaderDSA, setSearchedHeaderDSA] = useState(
    presistState?.searchedHeader?.dsA ?? []
  );
  const [searchedHeaderDSB, setSearchedHeaderDSB] = useState(
    presistState?.searchedHeader?.dsB ?? []
  );
  const [pageDSA, setPageDSA] = useState(
    presistState?.pagination?.pageDSA ?? 0
  );
  const [totalPagesDSA, setTotalPagesDSA] = useState(
    presistState?.pagination?.totalPagesDSA ?? 0
  );
  const [pageDSB, setPageDSB] = useState(
    presistState?.pagination?.pageDSB ?? 0
  );
  const [totalPagesDSB, setTotalPagesDSB] = useState(
    presistState?.pagination?.totalPagesDSB ?? 0
  );
  const [previousTransaction, setPreviousTransaction] = useState({
    dsA: [],
    dsB: [],
  });
  const [loaderDSA, setLoaderDSA] = useState(false);
  const [loaderDSB, setLoaderDSB] = useState(false);
  const [total, setTotal] = useState(0);
  const [difference, setDifference] = useState(0);
  const [showNotes, setShowNotes] = useState(false);
  const isAccounting = location?.state?.item?.isAccounting;
  const searchDSA = DynamicSubMenuItem?.pairs[0]?.dsAIsLocateTxnSearchEnabled;
  const searchDSB = DynamicSubMenuItem?.pairs[0]?.dsBIsLocateTxnSearchEnabled;
  const addNote = () => {
    let now = moment();
    let comment = document.getElementById("note").value;
    if (comment !== "") {
      let note = {
        comment: comment,
        author: `${JSON.parse(localStorage.getItem("userData")).firstName} ${
          JSON.parse(localStorage.getItem("userData")).lastName
        }`,
        date: now.format(date),
        time: now.hour() + ":" + now.minute(),
      };
      setNotes((prevState) => [...prevState, note]);
      document.getElementById("note").value = "";
      setNoteFlag(true);
    }
  };
  const dsASearches = (page, searchQuery) => {
    setLoaderDSA(true);
    let url = `/recon/fetch-pair-data/${
      DynamicSubMenuItem?.reconId
    }?recon_account_id=${selectedAccount?.id}&datasourceA_id=${
      dataSource?.datasourceAId
    }&search=${searchQuery}&is_locate_txn_flow=true&output_id=${
      selectedRow?.outputId
    }&page=${page}&size=${15}`;

    axiosInstance
      .get(url)
      .then((res) => {
        if (res.message.status !== "200") {
          setLoaderDSA(false);
        } else {
          if (
            res.data.transactions &&
            !_.isEqual(previousTransaction?.dsA, res?.data?.transaction)
          ) {
            setSearchedTransactionDSA((prevState) => {
              const filteredPrevState = prevState.filter(
                (transaction) =>
                  !selectedTransactionDSA.some((selectedTransaction) =>
                    _.isEqual(selectedTransaction, transaction)
                  )
              );

              const filteredNewTransactions = res?.data?.transactions.filter(
                (transaction) =>
                  !selectedTransactionDSA.some((selectedTransaction) =>
                    _.isEqual(selectedTransaction, transaction)
                  )
              );

              return [
                ...selectedTransactionDSA,
                ...filteredPrevState,
                ...filteredNewTransactions,
              ];
            });
            setPreviousTransaction((prevState) => {
              return { dsA: res?.data?.transactions, dsB: prevState?.dsB };
            });
            setSearchedHeaderDSA(res?.data?.headers);
            setPageDSA(res?.page?.number || 0);
            setTotalPagesDSA(res?.page?.totalPages || 0);
            setLoaderDSA(false);
          }
        }
      })
      .catch((err) => {
        setLoaderDSA(false);
      });
  };

  const dsBSearches = (page, searchQuery) => {
    setLoaderDSB(true);
    let url = `/recon/fetch-pair-data/${
      DynamicSubMenuItem?.reconId
    }?recon_account_id=${selectedAccount?.id}&datasourceB_id=${
      dataSource?.datasourceBId
    }&search=${searchQuery}&is_locate_txn_flow=true&output_id=${
      selectedRow?.outputId
    }&page=${page}&size=${15}`;

    axiosInstance
      .get(url)
      .then((res) => {
        if (res?.message?.status !== "200") {
          setLoaderDSB(false);
        } else {
          if (
            res.data.transactions &&
            !_.isEqual(previousTransaction?.dsB, res?.data?.transaction)
          ) {
            setSearchedTransactionDSB((prevState) => {
              const filteredPrevState = prevState.filter(
                (transaction) =>
                  !selectedTransactionDSB.some((selectedTransaction) =>
                    _.isEqual(selectedTransaction, transaction)
                  )
              );

              const filteredNewTransactions = res?.data?.transactions.filter(
                (transaction) =>
                  !selectedTransactionDSB.some((selectedTransaction) =>
                    _.isEqual(selectedTransaction, transaction)
                  )
              );
              return [
                ...selectedTransactionDSB,
                ...filteredPrevState,
                ...filteredNewTransactions,
              ];
            });
            setPreviousTransaction((prevState) => {
              return { dsA: prevState?.dsA, dsB: res?.data?.transactions };
            });
            setSearchedHeaderDSB(res?.data?.headers);
            setPageDSB(res?.page?.number || 0);
            setTotalPagesDSB(res?.page?.totalPages || 0);
            setLoaderDSB(false);
          }
        }
      })
      .catch((err) => {
        setLoaderDSB(false);
      });
  };
  const bindLocatedTransaction = () => {
    const body = {
      data: {
        targetOutputId: selectedRow?.outputId,
        reconConfigId: DynamicSubMenuItem?.reconId,
        locatedTransactions: [
          ...(selectedTransactionDSA || []).map((txn) => ({
            sourceOutputId: txn?.outputid ?? null,
            transactionId: txn?.id,
            datasourceId: dataSource?.datasourceAId,
          })),
          ...(selectedTransactionDSB || []).map((txn) => ({
            sourceOutputId: txn?.outputid ?? null,
            transactionId: txn?.id,
            datasourceId: dataSource?.datasourceBId,
          })),
        ],
        notes: notes,
        payload: {
          dsA: dataSource?.datasourceA,
          dsB: dataSource?.datasourceB,
          manualMatchTransaction: [
            {
              matching_flow: "Bind-Located-Transaction",
              matchStatus: selectedRow?.status,
              headers: ["status", ...header],
              matchingData: [
                [
                  ...selectedRow?.dsA.filter((obj) => {
                    return Object?.entries(obj || {}).every(([key, value]) => {
                      if (key === "status") {
                        return true;
                      }
                      return value !== null;
                    });
                  }),
                  ...selectedTransactionDSA,
                ],
                [
                  ...selectedRow?.dsB.filter((obj) => {
                    return Object?.entries(obj || {}).every(([key, value]) => {
                      if (key === "status") {
                        return true;
                      }
                      return value !== null;
                    });
                  }),
                  ...selectedTransactionDSB,
                ],
              ],
            },
          ],
        },
      },
    };
    axiosInstance
      .post("/recon/bind-located-transaction", body)
      .then((res) => {
        if (res?.message?.status === "200") {
          Toast(res?.message?.description, "success");
          setSearchedTransactionDSA([]);
          setSearchedTransactionDSB([]);
          setSelectedTransactionDSA([]);
          setSelectedTransactionDSB([]);
          setSearchedHeaderDSA([]);
          setSearchedHeaderDSB([]);
          setSearchQueryDSA("");
          setTempSearchDSA("");
          setSearchQueryDSB("");
          setTempSearchDSB("");
          setDifference(0);
          setTotal(0);
          setNotes([]);
          setNoteFlag(false);
          setShowModal(false);
          setShowNotes(false);
          !presistState?.adjustmentFlow && setPresistState(null);
        } else {
          Toast(res?.message?.description, "error");
        }
      })
      .catch((err) => {
        Toast(err?.response?.data?.message?.description, "error");
      });
  };

  const handleScrollDSA = (e) => {
    if (
      parseInt(e.target.scrollHeight - e.target.scrollTop) <=
      parseInt(e.target.clientHeight)
    ) {
      if (pageDSA < totalPagesDSA - 1) {
        if (searchDSA && searchQueryDSA !== "") {
          dsASearches(pageDSA + 1, searchQueryDSA);
        }
      }
    }
  };

  const handleScrollDSB = (e) => {
    if (
      parseInt(e.target.scrollHeight - e.target.scrollTop) <=
      parseInt(e.target.clientHeight)
    ) {
      if (pageDSB < totalPagesDSB - 1) {
        if (searchDSB && searchQueryDSB !== "") {
          dsBSearches(pageDSB + 1, searchQueryDSB);
        }
      }
    }
  };

  useEffect(() => {
    let leftTxns = 0;
    let rightTxns = 0;
    let leftTotal = 0;
    let rightTotal = 0;
    dataSource?.manualMatchingHeaders?.map((key) => {
      selectedTransactionDSA?.map((item) => {
        leftTxns += item[key] ? parseFloat(item[key]) : 0;
        leftTotal += item[key] ? parseFloat(item[key]) : 0;
      });
      selectedRow?.dsA?.map((item) => {
        leftTxns += item[key] ? parseFloat(item[key]) : 0;
      });
      selectedTransactionDSB?.map((item) => {
        rightTxns += item[key] ? parseFloat(item[key]) : 0;
        rightTotal += item[key] ? parseFloat(item[key]) : 0;
      });
      selectedRow?.dsB?.map((item) => {
        rightTxns += item[key] ? parseFloat(item[key]) : 0;
      });
    });
    setTotal(isAccounting ? leftTotal + rightTotal : leftTotal - rightTotal);
    setDifference(() =>
      isAccounting ? leftTxns + rightTxns : leftTxns - rightTxns
    );
  }, [selectedTransactionDSA, selectedTransactionDSB]);

  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  return (
    <>
      <div className="col-md-12">
        <Modal
          backdrop={true}
          size="xl"
          show={showModal}
          onHide={() => {
            setSearchedTransactionDSA([]);
            setSearchedTransactionDSB([]);
            setSelectedTransactionDSA([]);
            setSelectedTransactionDSB([]);
            setSearchedHeaderDSA([]);
            setSearchedHeaderDSB([]);
            setSearchQueryDSA("");
            setTempSearchDSA("");
            setSearchQueryDSB("");
            setTempSearchDSB("");
            setDifference(0);
            setTotal(0);
            setNotes([]);
            setShowModal(false);
            !presistState?.adjustmentFlow && setPresistState(null);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>Locate Transaction</Modal.Header>
          <Modal.Body scrollable="true">
            <div className="row">
              <div className={showNotes ? "col-8" : "col-12"}>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <div className="text-info">{dataSource?.datasourceA}</div>
                      {searchDSA && (
                        <div class="has-search">
                          <span class="fa fa-search form-control-feedback"></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search"
                            value={tempSearchDSA}
                            onChange={(e) => setTempSearchDSA(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                setPageDSA(0);
                                setSearchedTransactionDSA([]);
                                setSearchQueryDSA(tempSearchDSA);
                                if (tempSearchDSA === "")
                                  setSearchedTransactionDSA([
                                    ...selectedTransactionDSA,
                                  ]);
                                if (tempSearchDSA !== "")
                                  dsASearches(0, tempSearchDSA);
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {loaderDSA ? (
                      <div
                        className="d-flex justify-content-center mt-5"
                        style={{ height: "100px" }}
                      >
                        <LoaderComponent />
                      </div>
                    ) : (
                      <div
                        className={`table-responsive mt-1 mb-4 fancy-scroll`}
                        onScroll={handleScrollDSA}
                        style={{ maxHeight: "300px" }}
                      >
                        <table
                          className={`table table-dim-dark table-bordered table-custom-style ${style.tableStyle} m-0`}
                        >
                          <thead>
                            <tr>
                              <th></th>
                              {searchedTransactionDSA?.length > 0 && searchDSA
                                ? searchedHeaderDSA?.length > 0 &&
                                  searchedHeaderDSA?.map((column) => (
                                    <th
                                      className={`text-info ${
                                        column === "Balance" ||
                                        column === "Debit" ||
                                        column === "Credit"
                                          ? "text-right"
                                          : ""
                                      }`}
                                    >
                                      {column}
                                    </th>
                                  ))
                                : header?.map((column) => (
                                    <th
                                      className={`text-info ${
                                        column === "Balance" ||
                                        column === "Debit" ||
                                        column === "Credit"
                                          ? "text-right"
                                          : ""
                                      }`}
                                    >
                                      {column}
                                    </th>
                                  ))}
                            </tr>
                          </thead>

                          <tbody>
                            {selectedRow?.dsA?.map((row, index) => {
                              return (
                                <tr key={row.id}>
                                  <td></td>
                                  {header.map((key) =>
                                    key === "Balance" ||
                                    key === "Debit" ||
                                    key === "Credit" ? (
                                      <td key={key} className="text-right">
                                        {row[key]
                                          ? formatNumber(row[key])
                                          : null}
                                      </td>
                                    ) : (
                                      <td
                                        key={key}
                                        style={
                                          key === "Description"
                                            ? {
                                                whiteSpace: "normal", // or 'pre-wrap'
                                                wordWrap: "break-word",
                                                minWidth: "322px",
                                              }
                                            : null
                                        }
                                      >
                                        {key === "Posting Date"
                                          ? DateFormat(date, row[key])
                                          : row[key]}
                                      </td>
                                    )
                                  )}
                                </tr>
                              );
                            })}
                            {searchedTransactionDSA?.length > 0 &&
                              searchDSA &&
                              searchedTransactionDSA?.map((row, index) => (
                                <tr>
                                  <td>
                                    <input
                                      key={index}
                                      type="checkbox"
                                      checked={selectedTransactionDSA.some(
                                        (transaction) =>
                                          _.isEqual(transaction, row)
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setSelectedTransactionDSA(
                                            (prevState) => [...prevState, row]
                                          );
                                        } else {
                                          setSelectedTransactionDSA(
                                            (prevState) =>
                                              prevState.filter(
                                                (item) => !_.isEqual(item, row)
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </td>

                                  {searchedHeaderDSA.map((key) =>
                                    key === "Balance" ||
                                    key === "Debit" ||
                                    key === "Credit" ? (
                                      <td key={key} className="text-right">
                                        {row[key]
                                          ? formatNumber(row[key])
                                          : null}
                                      </td>
                                    ) : (
                                      <td
                                        key={key}
                                        style={
                                          key === "Description"
                                            ? {
                                                whiteSpace: "normal", // or 'pre-wrap'
                                                wordWrap: "break-word",
                                                minWidth: "322px",
                                              }
                                            : null
                                        }
                                      >
                                        {key === "Posting Date"
                                          ? DateFormat(date, row[key])
                                          : row[key]}
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div className="d-flex justify-content-between">
                      <div className="text-danger">
                        {/* {dsbAccount?.type + " - " + dsbAccount?.number} */}
                        {dataSource?.datasourceB}
                      </div>
                      {searchDSB && (
                        <div class="has-search">
                          <span class="fa fa-search form-control-feedback"></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search"
                            value={tempSearchDSB}
                            onChange={(e) => setTempSearchDSB(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                setPageDSB(0);
                                setSearchedTransactionDSB([]);
                                setSearchQueryDSB(tempSearchDSB);
                                if (tempSearchDSB === "")
                                  setSearchedTransactionDSB([
                                    ...selectedTransactionDSB,
                                  ]);
                                if (tempSearchDSB !== "")
                                  dsBSearches(0, tempSearchDSB);
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {loaderDSB ? (
                      <div
                        className="d-flex justify-content-center mt-5"
                        style={{ height: "100px" }}
                      >
                        <LoaderComponent />
                      </div>
                    ) : (
                      <div
                        className={`table-responsive mt-1 fancy-scroll`}
                        onScroll={handleScrollDSB}
                        style={{ maxHeight: "300px" }}
                      >
                        <table
                          className={`table table-dim-dark table-bordered table-custom-style ${style.tableStyle} m-0`}
                        >
                          <thead>
                            <tr>
                              <th></th>
                              {searchedTransactionDSB?.length > 0 && searchDSB
                                ? searchedHeaderDSB?.length > 0 &&
                                  searchedHeaderDSB?.map((column) => (
                                    <th
                                      className={`text-info ${
                                        column === "Balance" ||
                                        column === "Debit" ||
                                        column === "Credit"
                                          ? "text-right"
                                          : ""
                                      }`}
                                    >
                                      {column}
                                    </th>
                                  ))
                                : header?.map((column) => (
                                    <th
                                      className={`text-info ${
                                        column === "Balance" ||
                                        column === "Debit" ||
                                        column === "Credit"
                                          ? "text-right"
                                          : ""
                                      }`}
                                    >
                                      {column}
                                    </th>
                                  ))}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedRow?.dsB?.map((row, index) => (
                              <tr>
                                <td></td>
                                {header.map((key) =>
                                  key === "Balance" ||
                                  key === "Debit" ||
                                  key === "Credit" ? (
                                    <td key={key} className="text-right">
                                      {row[key] ? formatNumber(row[key]) : null}
                                    </td>
                                  ) : (
                                    <td
                                      key={key}
                                      style={
                                        key === "Description"
                                          ? {
                                              whiteSpace: "normal", // or 'pre-wrap'
                                              wordWrap: "break-word",
                                              minWidth: "322px",
                                            }
                                          : null
                                      }
                                    >
                                      {key === "Posting Date"
                                        ? DateFormat(date, row[key])
                                        : row[key]}
                                    </td>
                                  )
                                )}
                              </tr>
                            ))}
                            {searchedTransactionDSB?.length > 0 &&
                              searchDSB &&
                              searchedTransactionDSB?.map((row, index) => (
                                <tr>
                                  <td>
                                    <input
                                      key={index}
                                      type="checkbox"
                                      checked={selectedTransactionDSB.some(
                                        (transaction) =>
                                          _.isEqual(transaction, row)
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setSelectedTransactionDSB(
                                            (prevState) => [...prevState, row]
                                          );
                                        } else {
                                          setSelectedTransactionDSB(
                                            (prevState) =>
                                              prevState.filter(
                                                (item) => !_.isEqual(item, row)
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </td>
                                  {searchedHeaderDSB.map((key) =>
                                    key === "Balance" ||
                                    key === "Debit" ||
                                    key === "Credit" ? (
                                      <td key={key} className="text-right">
                                        {row[key]
                                          ? formatNumber(row[key])
                                          : null}
                                      </td>
                                    ) : (
                                      <td
                                        key={key}
                                        style={
                                          key === "Description"
                                            ? {
                                                whiteSpace: "normal", // or 'pre-wrap'
                                                wordWrap: "break-word",
                                                minWidth: "322px",
                                              }
                                            : null
                                        }
                                      >
                                        {key === "Posting Date"
                                          ? DateFormat(date, row[key])
                                          : row[key]}
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div className="d-flex justify-content-end mt-4">
                      <div className="col-8">
                        <div className="row text-right">
                          {difference !== 0 && (
                            <button
                              className="btn btn-info ml-3 btn-md"
                              onClick={() => {
                                setPresistState({
                                  selectedRow: {
                                    ...selectedRow,
                                    normalTxnDSA: selectedRow?.dsA,
                                    selectedDSA: selectedTransactionDSA,
                                    normalTxnDSB: selectedRow?.dsB,
                                    selectedDSB: selectedTransactionDSB,
                                    dsA: [
                                      ...selectedRow?.dsA,
                                      ...selectedTransactionDSA,
                                    ],
                                    dsB: [
                                      ...selectedRow?.dsB,
                                      ...selectedTransactionDSB,
                                    ],
                                  },
                                  adjustmentFlow: true,
                                  goBackToLocate: false,
                                  searchedHeader: {
                                    dsA: searchedHeaderDSA,
                                    dsB: searchedHeaderDSB,
                                  },
                                  searchedTransaction: {
                                    dsA: searchedTransactionDSA,
                                    dsB: searchedTransactionDSB,
                                  },
                                  pagination: {
                                    pageDSA,
                                    pageDSB,
                                    totalPagesDSA,
                                    totalPagesDSB,
                                  },
                                  searchQuery: {
                                    dsA: searchQueryDSA,
                                    dsB: searchQueryDSB,
                                  },
                                  tempSearch: {
                                    dsA: tempSearchDSA,
                                    dsB: tempSearchDSB,
                                  },
                                  notes: notes,
                                  noteFlag: noteFlag,
                                });
                                setShowModal(false);
                              }}
                              s
                            >
                              Match with Adjustment
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="row text-right">
                          <div className="col-6">Total</div>
                          <div className="col-6">{total}</div>
                        </div>
                        <div className="row text-right">
                          <div className="col-6">Difference</div>
                          <div className="col-6">{difference}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {showNotes && (
                <div className="col-4">
                  <div className="notes-wrapper" style={{ height: "550px" }}>
                    <h5 className="mb-3 color-gray-dim">Notes</h5>
                    <div
                      className="overflow-auto fancy-scroll"
                      style={{ height: "22em" }}
                    >
                      <div className="mt-2">
                        {notes?.map(({ author, comment, date, time }, i) => (
                          <ul className="rs-notes-container" key={i}>
                            <li className="rs-notes-item">
                              <h6>{author}</h6>
                              <div className="note-comment">{comment}</div>
                              <sub>
                                {date} {time}
                              </sub>
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                    <textarea
                      placeholder="Write a Note"
                      rows="3"
                      id="note"
                      className="textArea"
                    ></textarea>
                    <div className="w-100 mt-2">
                      <ButtonBasic
                        classes={"primary px-4 w-100"}
                        title={"Add Notes"}
                        onClick={() => addNote()}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <ButtonBasic
                title={showNotes ? "Hide Notes" : "Show Notes"}
                onClick={() => setShowNotes(!showNotes)}
              />
              <ButtonBasic
                title="Cancel"
                onClick={() => {
                  setSearchedTransactionDSA([]);
                  setSearchedTransactionDSB([]);
                  setSelectedTransactionDSA([]);
                  setSelectedTransactionDSB([]);
                  setSearchedHeaderDSA([]);
                  setSearchedHeaderDSB([]);
                  setSearchQueryDSA("");
                  setTempSearchDSA("");
                  setSearchQueryDSB("");
                  setTempSearchDSB("");
                  setDifference(0);
                  setTotal(0);
                  setNotes([]);
                  setNoteFlag(false);
                  setShowModal(false);
                  setShowNotes(false);
                  !presistState?.adjustmentFlow && setPresistState(null);
                }}
              />

              <CustomTooltip
                placement="top"
                tooltipId="button-tooltip-3"
                disabled={
                  notes?.length > 0 && difference === 0 && noteFlag
                    ? false
                    : true
                }
                disabledMessage={
                  difference !== 0 ? "Difference should be 0" : "Add Notes"
                }
                onClick={() => bindLocatedTransaction()}
                buttonText="Save"
              >
                Save
              </CustomTooltip>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default LocateTransactionModal;
