import React, { useState, useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import axiosInstance from "../../../utils/axiosInstance";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import AddIcon from "../../../assets/images/add-icon.png";
import EditIcon from "../../../assets/images/edit.png";
import TrashIcon from "../../../assets/images/trash.png";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import Toast from "../../../components/Toast/Toast";
import { Modal } from "react-bootstrap";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import ViewModal from "./ViewModal";
import { useDateTime } from "../../../components/Helper/DateTime";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import ShowForPermission from "../../../utils/permissionsWrapper";
import Pagination from "../../../components/pagination/pagination";
const teamSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required*"),
  type: Yup.string().required("Required*"),
  description: Yup.string().required("Required*"),
});
const teamInitialData = {
  name: "",
  type: "",
  description: "",
};

const TeamManagement = () => {
  const { setRefresh } = useDateTime();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [team, setTeam] = useState(teamInitialData);
  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const getTeams = async () => {
    try {
      setIsLoading(true);
      let sortOrder = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
      let requestUrl = `/team?page=${page - 1}&size=${pageSize}${sortOrder}`; // Adjust page index to start from 0

      const res = await axiosInstance.get(requestUrl);
      if (res.message.status !== "200") {
        Toast(res.message.description, "error");
        setIsLoading(false);
        setTotalElements(0);
        setData([]);
        setTotalPages(0);
      } else {
        setData(res.data);
        setPage(res?.page?.number + 1 || 1);
        setPageSize(res.page.size);
        setTotalPages(res.page.totalPages);
        setTotalElements(res?.page?.totalElements);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
      setTotalPages(0);
      setTotalElements(0);
      setIsLoading(false);
    }
  };

  const HandleDeleteTeam = () => {
    setIsLoading(true);
    axiosInstance
      .delete(`/team/${id}`)
      .then((res) => {
        if (res.message.status === "200") {
          setIsDeleteModal(false);
          setIsLoading(false);
          getTeams();
          Toast("Record deleted!", "success");
        } else {
          setIsLoading(false);
          setIsDeleteModal(false);
          Toast(res.message.description, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsDeleteModal(false);
        Toast(err.response.data.message.description, "error");
      });
  };
  const handleEdit = (values) => {
    setIsLoading(true);
    axiosInstance
      .put(`/team/${id}`, { data: values })
      .then((res) => {
        if (res.message.status == 200) {
          setIsEdit(false);
          setIsLoading(false);
          setShowModal(false);
          setTeam(teamInitialData);
          setId("");
          getTeams();
          Toast("Record updated!", "success");
        } else {
          Toast(res.message.description, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Toast(err.response.data.message.description, "error");
        setIsLoading(false);
      });
  };
  const handleAdd = (values) => {
    setIsLoading(true);
    axiosInstance
      .post("/team", { data: values })
      .then((res) => {
        if (res.message.status == 200) {
          setIsLoading(false);
          setShowModal(false);
          setId("");
          getTeams();
          Toast("Record added!", "success");
        } else {
          Toast(res.message.description, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        Toast(err.response.data.message.description, "error");
        console.log(err);
        setIsLoading(false);
      });
  };
  const handleSubmit = (values) => {
    isEdit ? handleEdit(values) : handleAdd(values);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setId(row.id);
    },
  };
  useEffect(() => {
    getTeams();
    setRefresh(() => () => {
      getTeams();
    });
    return () => {
      setRefresh(() => () => {});
    };
  }, [page, pageSize, sort.sort_by, sort.sort_order]);

  useEffect(() => {
    if (isEdit && id) {
      setTeam(data.find((team) => team.id === id));
    }
  }, [isEdit, id]);
  const columns = [
    {
      dataField: "id",
      text: "team Id",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Team Name",
      sort: true,
    },
    {
      dataField: "type",
      text: "Team Type",
      sort: true,
    },
    {
      dataField: "description",
      text: "Team Description",
      sort: true,
    },

    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row, index) => {
        return (
          <div>
            <ShowForPermission permission="clk_team_update">
              <img
                id={"editIconRow" + index}
                width={24}
                height={24}
                src={EditIcon}
                className={`right-btn mr-2`}
                alt="edit-btn"
              />
            </ShowForPermission>
            <ShowForPermission permission="clk_team_delete">
              <img
                id={"deleteIconRow" + index}
                width={24}
                height={24}
                src={TrashIcon}
                className={`right-btn mr-3`}
                alt="dlt-btn"
              />
            </ShowForPermission>
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
          if (e.target.alt === "edit-btn") {
            setId(row.id);
            setIsEdit(true);
            setShowModal(true);
          } else if (e.target.alt === "dlt-btn") {
            setIsDeleteModal(true);
            setId(row.id);
          }
        },
      },
    },
  ];
  return (
    <>
      {isLoading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div className="row page-header d-flex justify-content-between">
        <div className="col-sm-12 col-lg-12 page-header d-flex justify-content-between m-0">
          <div className="title">Team List</div>
          <div>
            <ShowForPermission permission="clk_team_add">
              <ButtonTransparent
                id="addBtn"
                onClick={() => {
                  setShowModal(true);
                  setIsEdit(false);
                  setTeam(teamInitialData);
                }}
                title={"Add Team"}
                icon={AddIcon}
              />
            </ShowForPermission>
          </div>
        </div>
      </div>
      <div style={{ opacity: !isLoading ? "1" : "0.07" }}>
        <div className="row mt-4">
          <div className="col-12">
            <BootstrapTable
              id="teamTable"
              columns={columns}
              bootstrap4={true}
              keyField="id"
              bordered={false}
              classes="rs-table table-layout-auto table-custom-style"
              wrapperClasses="overflow-y-auto fancy-scroll"
              data={data}
              rowEvents={rowEvents}
              remote={{ sort: true }}
              onTableChange={onSortTable}
              noDataIndication={"No data found!"}
            />
          </div>
          <div className="col-12 mb-3">
            <Pagination
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalPages={totalPages}
              totalElements={totalElements}
              data={data || []}
            />
          </div>
        </div>

        <ConfirmationModal
          isShow={isDeleteModal}
          title={"Delete team"}
          message={"Are you sure you want to delete this team?"}
          hideModal={() => {
            setIsDeleteModal(false);
          }}
          confirmAction={() => HandleDeleteTeam()}
        />

        <Modal
          backdrop={true}
          size="lg"
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Formik
            initialValues={team}
            enableReinitialize={true}
            validationSchema={teamSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, handleSubmit, values }) => (
              <Form id="add-user" className="form" onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>{isEdit ? "Edit Team" : "Add Team"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row mt-3">
                    <div className="col-12 mt-3">
                      <div className="d-flex flex-column">
                        <label className="form-label">Team Name</label>
                        <Field
                          id="name"
                          className="form-control rs-input"
                          type="text"
                          placeholder="Enter Team Name"
                          name="name"
                        />
                        {errors.name && touched.name ? (
                          <div className="field-error">{errors.name}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="d-flex flex-column">
                        <label className="form-label">Team Type</label>
                        <Field
                          id="type"
                          className="form-control rs-input"
                          type="text"
                          placeholder="Enter Team Type"
                          name="type"
                        />
                        {errors.type && touched.type ? (
                          <div className="field-error">{errors.type}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="d-flex flex-column">
                        <label className="form-label">Team Description</label>

                        <Field
                          id="description"
                          className="form-control rs-input"
                          type="text"
                          placeholder="Enter Team Description"
                          name="description"
                        />
                        {errors.description && touched.description ? (
                          <div className="field-error">
                            {errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex">
                    <ButtonBasic
                      id="cancelBtn"
                      title="Cancel"
                      onClick={() => {
                        setShowModal(false);
                        setTeam(teamInitialData);
                        setId("");
                      }}
                    />
                    <button
                      id="submitBtn"
                      type="submit"
                      name="submit"
                      className={"btn btn-info btn-md mr-2"}
                      onClick={handleSubmit}
                    >
                      {isEdit ? "Update" : "Add"}
                    </button>
                  </div>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    </>
  );
};

export default TeamManagement;
