import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import { Modal } from "react-bootstrap";
import arrowRight from "../../assets/images/arrow-right-lg.png";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import moment from "moment";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import Button from "react-bootstrap/Button";
import axiosInstance from "../../utils/axiosInstance";
import { transactionFlowNames as flowNames } from "../../constants/Constants";
import { TransactionLogStyle } from "./TransactionsStyle";
import { useDateTime } from "../../components/Helper/DateTime";
import Toast from "../../components/Toast/Toast";
import { nullChecker } from "../../components/Helper/NullChecker";
import ButtonTransparent from "../../components/Buttons/ButtonTransparent";
import chevronDown from "../../assets/images/chevron-down.png";
import chevronUp from "../../assets/images/chevron-up.png";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { DateFormat } from "../../utils/formatDateTime";

const TlModalSchema = Yup.object().shape({
  comment: Yup.string().min(2, "Too Short!").required("Required*"),
});

const TransactionlogModal = ({
  show,
  onHide,
  transactionId,
  setShowModal,
  filter,
  pagination,
  showMatchButton,
}) => {
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { date, setPresistState } = useDateTime();
  const [isLoading, setIsLoading] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const [detailsView, setDetailsView] = useState("");
  const [showNotes, setShowNotes] = useState(false);

  const getTransactions = useCallback(async () => {
    setIsLoading(true);
    let _transactionData;
    try {
      const apiResponse = await axiosInstance.get(
        `/transactions/${transactionId}`
      );
      if (apiResponse.message.status !== "200") {
        setIsLoading(false);
        Toast(apiResponse.message.description, "error");
      } else {
        setIsLoading(false);
        _transactionData = apiResponse.data;
        setTransactionData(_transactionData);
      }
    } catch (e) {
      setIsLoading(false);
      Toast(e.message, "error");
      setIsLoading(false);
    }
  }, [transactionId]);

  useEffect(() => {
    if (show) {
      getTransactions();
    }
  }, [getTransactions]);

  const DataSourceTransactions = ({ data }) => {
    return data !== null ? (
      <div>
        <>{"{"}</>
        {Object.entries(JSON.parse(data)).map(([key, value]) => {
          return (
            <div>
              <span style={{ color: "#1196c2" }}>
                {'"'}
                {key}
                {'"'}
              </span>
              <span style={{ color: "#ef476f" }}>
                : {'"'}
                {value}
                {'"'}
              </span>
            </div>
          );
        })}
        <>{"}"}</>
      </div>
    ) : (
      "No Record Found!"
    );
  };

  const handleAddNotes = async (values) => {
    setIsLoading(true);
    let now = moment();
    const data = [
      {
        author: `${userData?.firstName} ${userData?.lastName}`,
        comment: values?.comment,
        date: now.format(date),
        time: now.hour() + ":" + now.minute(),
      },
    ];
    await axiosInstance
      .put(`/transactions/add-note?transactionId=${transactionId}`, data)
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          getTransactions();
          setIsLoading(false);
          Toast("Notes added successfully!", "success");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Toast(error.response.data.message.description, "error");
      });
  };
  const handleMatching = (
    matchingData,
    pairId,
    datasourceAId,
    datasourceBId,
    matchingFilterId
  ) => {
    let matchingFlag = true;
    matchingData.map(({ matched }) => {
      if (matched !== false) {
        matchingFlag = false;
      }
    });
    if (matchingFlag) {
      return (
        <div className="d-flex justify-content-center">
          <ButtonTransparent
            title="Match This Transaction"
            width={30}
            height={30}
            onClick={() => {
              if (matchingFlag) {
                setPresistState({
                  filter,
                  transactionId: transactionId,
                  ...pagination,
                });
                history.push(
                  `/matching?id=${pairId}&business_date=${filter?.business_date?.filter[0]}&from=${datasourceAId}&to=${datasourceBId}&matchingFilter=${matchingFilterId}&txnId=${transactionId}`
                );
              }
            }}
          />
        </div>
      );
    }
  };
  return (
    <>
      {isLoading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}

      <TransactionLogStyle>
        <Modal
          backdrop={true}
          size="xl"
          show={show}
          onHide={() => {
            onHide();
              setShowNotes(false);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <div className="color-dark">Transaction Detail</div>
          </Modal.Header>
          <Modal.Body scrollable="true">
            <div className="row">
              <div
                className={`${
                  showNotes ? "col-lg-8" : "col-lg-12"
                } col-sm-12 mb-3`}
              >
                <div className="row">
                  <div className="d-flex px-3 align-items-center">
                    <h5
                      className="mb-3 color-dark mr-2"
                      style={{ fontSize: "18px" }}
                    >
                      Transaction Flow
                    </h5>
                    <div className="legend-wrapper d-flex align-items-center mb-3">
                      {transactionData?.transactionFlow?.map((flow, i) => {
                        return (
                          <div
                            className="tsg-badge-rounded danger tsg-badge-sm"
                            key={i}
                          >
                            <div className="legend-title color-light">
                              {flowNames.map((obj) =>
                                obj.id === flow ? obj.name : null
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div
                        className="col-12 overflow-auto"
                        style={{ height: "auto" }}
                      >
                        {transactionData?.matchPair?.map(
                          (
                            {
                              datasourceA,
                              datasourceB,
                              matchingData,
                              dsATxn,
                              dsBTxn,
                              pairId,
                              datasourceAId,
                              datasourceBId,
                              matchingFilterId,
                            },
                            i
                          ) => (
                            <div key={i}>
                              <div className="matching-rule-wrapper my-3">
                                <div className="d-flex justify-content-between mb-3">
                                  <div className="d-flex flex-row align-items-center">
                                    <div className="color-lightBlue flow-badge atm">
                                      {datasourceA}
                                    </div>
                                    <span className="d-inline-flex flex-column mx-2 justify-content-center">
                                      <img
                                        style={{
                                          width: "20px",
                                          filter: "brightness(0.5)",
                                        }}
                                        src={arrowRight}
                                        alt="arrow-right"
                                      />
                                    </span>
                                    <div className="color-success flow-badge switch">
                                      {datasourceB}
                                    </div>
                                  </div>
                                  {showMatchButton &&
                                    handleMatching(
                                      matchingData,
                                      pairId,
                                      datasourceAId,
                                      datasourceBId,
                                      matchingFilterId
                                    )}
                                  <span key={i}>
                                    <ButtonTransparent
                                      title=""
                                      icon={
                                        detailsView === i
                                          ? chevronUp
                                          : chevronDown
                                      }
                                      width={30}
                                      height={30}
                                      onClick={() =>
                                        detailsView === i
                                          ? setDetailsView("")
                                          : setDetailsView(i)
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                              {detailsView !== i ? (
                                <>
                                  {" "}
                                  <table
                                    className="table table-dim-dark table-borderless transaction-flow-modal table-layout-auto fancy-scroll"
                                    style={{ pointerEvents: "none" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th scope="col"></th>
                                        {matchingData.map(
                                          ({ label, matched }, i) => {
                                            return (
                                              <th
                                                scope="col"
                                                key={i}
                                                style={{
                                                  color: matched
                                                    ? ""
                                                    : "#ef476f",
                                                }}
                                              >
                                                {label}
                                              </th>
                                            );
                                          }
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="text-bold">
                                          {datasourceA}
                                        </td>
                                        {matchingData.map(
                                          (
                                            { datasourceA, matched, label },
                                            i
                                          ) => (
                                            <td
                                              key={i}
                                              style={{
                                                color: matched ? "" : "#ef476f",
                                              }}
                                            >
                                              {label.toLowerCase() ===
                                              "posting date"
                                                ? datasourceA.value
                                                  ? DateFormat(
                                                      date,
                                                      datasourceA.value
                                                    )
                                                  : nullChecker(
                                                      datasourceA.value
                                                    )
                                                : nullChecker(
                                                    datasourceA.value
                                                  )}
                                            </td>
                                          )
                                        )}
                                      </tr>
                                      <tr>
                                        <td className="text-bold">
                                          {datasourceB}
                                        </td>
                                        {matchingData.map(
                                          (
                                            { datasourceB, matched, label },
                                            i
                                          ) => (
                                            <td
                                              key={i}
                                              style={{
                                                color: matched ? "" : "#ef476f",
                                              }}
                                            >
                                              {label.toLowerCase() ===
                                              "posting date"
                                                ? datasourceB.value
                                                  ? DateFormat(
                                                      date,
                                                      datasourceB.value
                                                    )
                                                  : nullChecker(
                                                      datasourceB.value
                                                    )
                                                : nullChecker(
                                                    datasourceB.value
                                                  )}
                                            </td>
                                          )
                                        )}
                                      </tr>
                                    </tbody>
                                  </table>
                                </>
                              ) : (
                                <div className="row">
                                  <div className="col-lg-6">
                                    <DataSourceTransactions data={dsATxn} />
                                  </div>
                                  <div className="col-lg-6">
                                    <DataSourceTransactions data={dsBTxn} />
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {showNotes && (
                <div className="col-lg-4 col-sm-12">
                  <div className="notes-wrapper">
                    <h5 className="mb-3 color-gray-dim">Notes</h5>
                    <div className="overflow-auto" style={{ height: "22em" }}>
                      {transactionData?.notes?.map(
                        ({ author, comment, date, time }, i) => (
                          <ul className="rs-notes-container" key={i}>
                            <li className="rs-notes-item">
                              <h6>{author}</h6>
                              <div className="note-comment">{comment}</div>
                              <sub>
                                {date} {time}
                              </sub>
                            </li>
                          </ul>
                        )
                      )}
                    </div>
                    <Formik
                      initialValues={{
                        comment: "",
                      }}
                      validationSchema={TlModalSchema}
                      onSubmit={(values, { resetForm }) => {
                        handleAddNotes(values);
                        values.comment = "";
                        resetForm();
                      }}
                      onReset={({ resetForm }) => resetForm()}
                    >
                      {({ errors, touched, handleSubmit, handleReset }) => (
                        <Form
                          id="add-notes"
                          className="form"
                          onSubmit={handleSubmit}
                          onReset={handleReset}
                        >
                          <div className="row mt-3">
                            <div className="col-12 mt-3">
                              <div className="d-flex flex-column">
                                <Field
                                  as="textarea"
                                  className="rs-input"
                                  placeholder="Write a Note"
                                  rows="3"
                                  name="comment"
                                />
                                {errors.comment && touched.comment ? (
                                  <div className="field-error">
                                    {errors.comment}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="w-100 mt-2">
                            <ButtonBasic
                              type="submit"
                              classes={"primary px-4 w-100"}
                              title={"Add Note"}
                              onClick={handleSubmit}
                            />
                          </div>
                          <div className="w-100 mt-2">
                            <Button
                              className="btn btn-secondary px-4 w-100"
                              onClick={() => {
                                setShowModal(false);
                                setShowNotes(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end w-100">
              <ButtonBasic
                title={showNotes ? "Hide Notes" : "Show Notes"}
                onClick={() => setShowNotes(!showNotes)}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </TransactionLogStyle>
    </>
  );
};
export default TransactionlogModal;
