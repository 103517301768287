import React, { useContext, useState, useEffect } from "react";
import { Formik, isEmptyArray } from "formik";
import CurrencyFormat from "react-currency-format";
import "react-datepicker/dist/react-datepicker.css";
import AddCassettesConfiguration from "./AddCassettesConfiguration";
import { useDateTime } from "../../../../../components/Helper/DateTime";
import MultiStepFormContext from "./MultiStepFormContext";

export const EjCashLoad = () => {
  const {
    ejCashLoadData,
    setEjCashLoadData,
    next,
    prev,
    devicesData,
    setCassetteUnits,
    details,
    setDetails,
    selectedDeviceId,
  } = useContext(MultiStepFormContext);

  const { currency } = useDateTime();
  const [errors, setErrors] = useState({});

  const addTableRows = () => {
    const rowsInput = {
      cassetteType: "",
      denomination: "",
      billCount: "",
      isNewRow: true,
    };
    setEjCashLoadData([...ejCashLoadData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...ejCashLoadData];
    rows.splice(index, 1);
    setEjCashLoadData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    if (value)
      setErrors((prev) => ({
        ...prev,
        billCount: {
          ...prev.billCount,
          [index]: null,
        },
      }));
    setEjCashLoadData((prevState) => {
      const rowsInput = [...prevState];
      rowsInput[index][name] = value;
      return rowsInput;
    });
  };

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );

  const grandTotal = () => {
    setDetails({
      ...details,
      ejCashLoadTotalAmount: ejCashLoadData?.reduce(
        (acc, row) =>
          acc + parseInt(row.denomination ?? 0) * parseInt(row.billCount),
        0
      ),
    });
  };

  useEffect(() => {
    if (selectedDeviceId) {
      if (!isEmptyArray(ejCashLoadData)) {
        return;
      } else {
        const selectedDevice = devicesData?.find(
          (item) => item.deviceId === selectedDeviceId
        );
        let deviceCassettes = selectedDevice?.atmCassetteConfig?.map(
          (item) => ({
            denomination: item.denom,
            cassetteType: item.type,
            billCount: "",
          })
        );
        setEjCashLoadData(deviceCassettes || []);
        setCassetteUnits(deviceCassettes || []);
      }
    } else {
      setEjCashLoadData([]);
      setCassetteUnits([]);
    }
  }, [
    details.deviceId,
    devicesData,
    setEjCashLoadData,
    setCassetteUnits,
    ejCashLoadData,
    selectedDeviceId,
  ]);

  useEffect(() => {
    grandTotal();
  }, [ejCashLoadData]);

  return (
    <Formik
      initialValues={ejCashLoadData}
      enableReinitialize={true}
      onSubmit={(values) => {
        let error = false;
        values.forEach((el, i) => {
          if (!el.billCount || isNaN(el.billCount)) {
            error = true;
            setErrors((prev) => ({
              ...prev,
              billCount: {
                ...prev.billCount,
                [i]: "Required number",
              },
            }));
          }
        });
        if (!error) {
          setEjCashLoadData(values);
          next();
        }
      }}
    >
      {({ handleSubmit, touched }) => {
        return (
          <>
            <div className="container">
              <div className="row pt-3">
                <h5 className="pl-3">{"Cash Load > ATM Slip"}</h5>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="row mt-3 d-flex justify-content-start  align-items-start">
                    <div className="col-md">
                      <div className="text-left">Denomination</div>
                      {/* <p className={"error__feedback"}>{errors.denomination}</p> */}
                    </div>
                    <div className="col-md">
                      <div className="text-left">Bill Count</div>
                    </div>

                    <div className="col-md">
                      <div className="text-left">Cassette Type</div>
                    </div>

                    <div className="col-md">
                      <div className="text-left">Total</div>
                    </div>

                    {/* <div className="col-md">
                      <button
                        className="btn btn-outline-success"
                        type="button"
                        onClick={addTableRows}
                      >
                        +
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-10">
                  <AddCassettesConfiguration
                    rowsData={[ejCashLoadData]}
                    deleteTableRows={deleteTableRows}
                    handleChange={handleChange}
                    isCassetteType={true}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-5">
                  <div className="text-center">
                    Total Cash Load:{" "}
                    {formatAmount(details?.ejCashLoadTotalAmount)}
                  </div>
                </div>

                <div className="d-flex">
                  <button className="btn btn-info" onClick={prev}>
                    Back
                  </button>
                  <button className="btn btn-info ml-2" onClick={handleSubmit}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
export default EjCashLoad;
