import React, { createRef } from "react";
import AddIcon from "../../../assets/images/add-icon.png";
import { nullChecker } from "../../../components/Helper/NullChecker";
import BootstrapTable from "react-bootstrap-table-next";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import EditIcon from "../../../assets/images/edit.png";
import TrashIcon from "../../../assets/images/trash.png";
import { withRouter } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";
// import { baseUrl } from "../../../configuration/apiUrl";
import FilterIcon from "../../../assets/images/filter.png";
import FilterDropdown from "../../../components/FilterDropdown/FilterDropdown";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ShowForPermission from "../../../utils/permissionsWrapper";
import buttonChecker from "../../../utils/buttonsPermissionsChecker";
import Toast from "../../../components/Toast/Toast";
import withDateTime from "../../../utils/timedatehoc";
import { regionCheck, mapdata } from "../../../utils/regionHelper";
import Pagination from "../../../components/pagination/pagination";

class BranchList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.filter = createRef();
    this.onSortTable = this.onSortTable.bind(this);
    this.getTotalSize = this.getTotalSize.bind(this);
    this.getSizePerPage = this.getSizePerPage.bind(this);
    this.getActions = this.getActions.bind(this);
    this.editBranch = this.editBranch.bind(this);
    // this.deleteATM = this.deleteATM.bind(this);
    this.pagination = {
      onPageChange: this.onPageChanges.bind(this),
      pageStartIndex: 1, // where to start counting the pages
      totalSize: 0,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      showTotal: true,
      paginationTotalRenderer: this.totalRecords.bind(this),
      alwaysShowAllBtns: true,
      paginationShowsTotal: false,
      sizePerPage: 15,
      page: 1,
      totalPages: 0,
      searchText: "",
      sizePerPageList: [
        {
          text: "15",
          value: 15,
        },
        {
          text: "30",
          value: 30,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
      onSizePerPageChange: this.onSizeChange.bind(this),
    };

    this.state = {
      modalShow: false,
      branchesList: [],
      regionFilters: "",
      regions: [],
      sortField: "",
      sortOrder: "",
      loader: true,
      confirmationModal: false,
      limit: 15,
      page: 0,
      deleteBranchId: undefined,
      regionList: [],
    };
  }
  mapRegions = (regions) => {
    const arr = [];
    // eslint-disable-next-line
    regions.map((region) => {
      arr.push({
        id: region.codeValue,
        label: region.label,
      });
      if (region?.children) {
        arr.push(...this.mapRegions(region.children));
      }
    });
    return arr;
  };
  getRegions = () => {
    axiosInstance
      .get("/hierarchical/filters?screen_id=branch_management")
      .then((res) => {
        let _filters = res.data.filters;
        let regions = _filters.filter(
          ({ filterName }) => filterName === "region"
        );
        let filter = "";
        if (regions.length > 0) {
          regions[0].filterData = mapdata(regions[0].filterData);
          regions[0].filterData.forEach((x) => {
            x.checked = x.param1 === "1" ? true : false;
          });
          filter = `&region_id=${regions[0].filterData
            .map(({ codeValue }) => codeValue)
            .join(",")}`;
          this.filter.current = filter;
        }
        this.setState(
          {
            regions: regions,
            regionList: this.mapRegions(res.data?.filters[0].filterData),
            loader: false,
            regionFilters: filter,
          },
          () => {
            this.getDevices(this.state.limit, 0, filter, this.state.searchText);
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loader: false });
      });
  };

  //#region Table Actions
  onSortTable(type, data) {
    let { sortField, sortOrder } = this.state;
    let page = this.pagination.page;
    if (type === "sort") {
      sortField = data.sortField;
      sortOrder = data.sortOrder;
      this.setState({ loader: true, sortField, sortOrder }, () => {
        this.getDevices(
          this.state.limit,
          page - 1,
          this.filter.current,
          this.state.searchText
        );
      });
    }
  }
  getActions(cell, row, rowIndex) {
    return (
      <div>
        <img
          id={"editIconRow" + rowIndex}
          width={24}
          height={24}
          src={EditIcon}
          className={`right-btn mr-2`}
          alt="edit btn"
          style={{ opacity: buttonChecker("clk_branch_edit") ? "1" : "0.3" }}
          // onClick={() => this.editATM(row.deviceId)}
        />
        <img
          id={"deleteIconRow" + rowIndex}
          width={24}
          height={24}
          src={TrashIcon}
          className={`right-btn mr-3`}
          alt="dlt btn"
          style={{ opacity: buttonChecker("clk_branch_del") ? "1" : "0.3" }}
          // onClick={() => this.deleteATM(row.deviceId)}
        />
      </div>
    );
  }

  editBranch(id) {
    buttonChecker("clk_branch_edit") &&
      this.props.history.push(`/update-branch-management?id=${id}`);
  }

  deleteBranch(id) {
    this.setState({ confirmationModal: true, deleteBranchId: id });
  }

  async confrimDeleteBranch() {
    if (buttonChecker("clk_branch_del")) {
      try {
        const response = await axiosInstance.delete(
          `/branches/${this.state.deleteBranchId}`
        );
        if (response.message.status === "200") {
          const { branchesList } = this.state;
          const _branchesList = branchesList.filter(
            (branch) => branch.id !== this.state.deleteBranchId
          );
          this.setState({
            confirmationModal: false,
            deleteBranchId: undefined,
            branchesList: _branchesList,
          });
          Toast("Branch deleted successfully!", "success");
        } else {
          Toast(response.message.message, "error");
        }
      } catch (err) {
        console.log("err deleting device ", err);
      }
    }
  }

  //#endregion
  //#region Pagination Functions
  onPageChanges = async (page) => {
    this.setState({ loader: true }, () => {
      this.setCurrentPage(page);
      let sizePerPage = this.pagination.sizePerPage;
      this.getDevices(
        sizePerPage,
        page - 1,
        this.filter.current,
        this.state.searchText
      );
    });
  };
  setCurrentPage(page) {
    let _options = { ...this.pagination };
    _options["page"] = page;
    this.pagination = _options;
  }
  onSizeChange = async (sizePerPage) => {
    this.setState({ loader: true }, () => {
      this.setCurrentSize(sizePerPage);
      let page = this.pagination.page;
      this.getDevices(
        sizePerPage,
        page - 1,
        this.filter.current,
        this.state.searchText
      );
    });
  };
  setCurrentSize(sizePerPage) {
    let _options = { ...this.pagination };
    _options["size"] = sizePerPage;
    this.pagination = _options;
  }
  totalRecords = () => (
    <span className="d-inline text-muted" style={{ float: "right" }}>
      Total Records: {this.pagination.totalSize}
    </span>
  );

  getTotalSize(response) {
    return response.page ? response.page.totalElements : 0;
  }
  getSizePerPage(response) {
    return response.page ? response.page.size : 15; //change 4 by API response
  }
  //#endregion
  componentDidMount() {
    this.getRegions();
    this.props.dateTime.setRefresh(() => () => {
      this.setState({ loader: true }, () => {
        this.getRegions();
      });
    });
  }

  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }

  async getDevices(limit, page, filter, searchText) {
    let Pagination = this.pagination;
    try {
      const { sortField, sortOrder } = this.state;
      let url = `/branches?page=${page}&size=${limit}${
        this.filter.current ? this.filter.current : filter ? filter : ""
      }&search=${searchText ?? ""}`;
      if (sortOrder && sortField) {
        url += `&sort_order=${sortOrder}&sort_by=${sortField}`;
      }

      // if (this.filter.current) {
      //   url += this.filter.current;
      // } else {
      //   if (filter) {
      //     url += filter;
      //   }
      // }
      const response = await axiosInstance.get(url);
      // console.log("response of branches.....", response);
      if (response.message.status !== "200") {
        // Toast(response.message.description, "error");
        this.setState({ branchesList: [], loader: false });
      } else {
        if (response && response.data && response.data.branches) {
          const _branchesList = response.data.branches;
          let pagesize = this.getSizePerPage(response);
          let totalElements = this.getTotalSize(response);
          this.pagination.page = response?.page?.number + 1 || 1;
          Pagination.sizePerPage = pagesize;
          Pagination.totalSize = totalElements;
          Pagination.totalPages = response?.page?.totalPages;
          this.setState({
            branchesList: _branchesList,
            loader: false,
          });
        } else {
          this.setState({ branchesList: [], loader: false });
        }
        this.pagination = {
          ...this.pagination,
          pageSize: Pagination.sizePerPage,
          totalSize: Pagination.totalSize,
          totalPages: Pagination.totalPages,
        };
      }
    } catch (e) {
      console.log("on page change error ", e);
      this.setState({ branchesList: [], loader: false });
    }
  }

  onRegionDropdownChange(value) {
    if (value?.length > 0) {
      // console.log(value);
      this.filter.current = `&region_id=${value
        .map((node) => node.codeValue)
        .join(",")}`;
    } else {
      this.filter.current = "";
    }
    const regions = this.state.regions;
    regions.filterData = regionCheck(this.state.regions[0].filterData, value);
    this.setState({ regions });
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      this.props.history.push(`/view-branch-management?id=${row.id}`);
    },
  };

  render() {
    const _region = this.state.regions;
    const columns = [
      { hidden: true, dataField: "id", text: "ID" },
      {
        dataField: "name",
        text: "Name",
        formatter: nullChecker,
        sort: true,
        headerAttrs: { id: "branchNameSort" },
      },
      {
        dataAlign: "left",
        dataField: "branchCode",
        text: "Branch Code",
        sort: true,
        formatter: (cell) => (cell ? cell : "-"),
        headerAttrs: { id: "branchCodeSort" },
      },
      {
        dataAlign: "left",
        dataField: "address1",
        text: "Address",
        sort: true,
        formatter: (cell) => (cell ? cell : "-"),
        headerAttrs: { id: "addressSort" },
      },
      {
        dataAlign: "left",
        dataField: "city",
        text: "City",
        sort: true,
        formatter: (cell) => (cell ? cell : "-"),
        headerAttrs: { id: "citySort" },
      },
      {
        dataAlign: "left",
        dataField: "regionId",
        text: "Region",
        sort: true,
        formatter: (cell) =>
          // eslint-disable-next-line
          cell ? this.state.regionList.find((x) => x.id == cell)?.label : "-",
        headerAttrs: { id: "regionSort" },
      },

      {
        dataField: "action",
        text: "Action",
        formatter: this.getActions,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            e.stopPropagation();
            if (
              buttonChecker("clk_branch_edit") &&
              e.target.alt === "edit btn"
            ) {
              this.editBranch(row.id);
            } else if (
              buttonChecker("clk_branch_del") &&
              e.target.alt === "dlt btn"
            ) {
              this.deleteBranch(row.id);
            }
          },
        },
      },
    ];

    return (
      <>
        {(this.state.loader || this.state.regionList.length < 0) && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div
          style={{
            opacity:
              !this.state.loader && this.state.regionList.length > 0
                ? "1"
                : "0.07",
          }}
        >
          <div className="row align-items-center d-xs-none d-lg-flex">
            {_region?.map((filter) => {
              return (
                <div
                  className="col-3"
                  style={{ minWidth: "210px" }}
                  key={filter.filterId}
                >
                  <FilterDropdown
                    title={filter.filterLabel}
                    type="checkbox"
                    data={filter.filterData}
                    onDropdownChange={(value) =>
                      this.onRegionDropdownChange(value)
                    }
                  />
                </div>
              );
            })}
            {!this.state.loader && (
              <div className="col d-flex justify-content-start align-items-center">
                <ButtonBasic
                  title="Filter"
                  icon={FilterIcon}
                  onClick={() =>
                    this.setState({ loader: true }, () =>
                      this.getDevices(
                        this.state.limit,
                        0,
                        this.filter.current,
                        this.state.searchText
                      )
                    )
                  }
                />
              </div>
            )}
            <div className="col d-flex justify-content-end align-items-center">
              <ShowForPermission permission="clk_branch_add">
                <ButtonTransparent
                  id="addBranchBtn"
                  onClick={() =>
                    this.props.history.push("/add-branch-management", {
                      action: "add",
                    })
                  }
                  title={"Add Branch"}
                  icon={AddIcon}
                />
              </ShowForPermission>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <div className="row page-header d-flex justify-content-between align-items-center mb-0">
                <div className="title col-8">All Branches</div>
                <div className="col-4">
                  <div class="form-group has-search">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search by Branch Code or Branch Name"
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          this.getDevices(
                            this.state.limit,
                            0,
                            this.filter.current,
                            e.target.value
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {this.state.regionList.length > 0 && (
                    <>
                      {" "}
                      <BootstrapTable
                        id="branchTable"
                        bootstrap4={true}
                        bordered={false}
                        classes="rs-table table-layout-auto mb-0 table-custom-style"
                        wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                        keyField="deviceId"
                        remote={{ pagination: true, sort: true }}
                        data={this.state.branchesList}
                        columns={columns}
                        onTableChange={(type, newState) =>
                          this.onSortTable(type, newState)
                        }
                        rowEvents={this.rowEvents}
                        noDataIndication="No data found!"
                      ></BootstrapTable>
                      <Pagination
                        setPage={this.onPageChanges}
                        setPageSize={this.onSizeChange}
                        pageSize={this.pagination.sizePerPage}
                        totalPages={this.pagination.totalPages}
                        totalElements={this.pagination.totalSize}
                        page={this.pagination.page}
                        data={this.state.branchesList || []}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ConfirmationModal
            isShow={this.state.confirmationModal}
            title={"Delete Branch "}
            message={"Are you sure you want to delete this Branch ?"}
            hideModal={() => {
              this.setState({ confirmationModal: false });
            }}
            confirmAction={() => this.confrimDeleteBranch()}
          />
        </div>
      </>
    );
  }
}

export default withDateTime(withRouter(BranchList));
