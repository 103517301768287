import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import "../../../assets/styles/appStyles.scss";
import logo from "../../../assets/images/logo-sm.svg";
import logoNormal from "../../../assets/images/logo.png";
import logoDark from "../../../assets/images/logo-dark.png";
import dashboard from "../../../assets/images/home-light.png";
import accounts from "../../../assets/images/wallet.png";
import Operations from "../../../assets/images/operations.png";
import chevDown from "../../../assets/images/chevron-down.png";
import chevUp from "../../../assets/images/chevron-up.png";
import atmLight from "../../../assets/images/atm-new-light.png";
import posLight from "../../../assets/images/pos.png";
import branchIcon from "../../../assets/images/branch.png";
import reportsIcon from "../../../assets/images/reports.png";
import paymentIcon from "../../../assets/images/payment.png";
import transactionIcon from "../../../assets/images/transaction-light.png";
import adminIcon from "../../../assets/images/admin-light.png";
import log from "../../../assets/images/log.png";
import workqueue from "../../../assets/images/work-queue.png";
import { useTranslation } from "react-i18next";
import ShowForPermission from "../../../utils/permissionsWrapper";
import { pswMockMenu } from "./psw-mock-menu";
import { NavLink, useLocation } from "react-router-dom";
import SidebarStyle from "./SidebarStyle";
import axiosInstance from "../../../utils/axiosInstance";
import { useDateTime } from "../../../components/Helper/DateTime";

const Sidebar = ({
  navBar,
  showNavBar,
  toggleNavLink,
  togglePosLink,
  toggleBranchLink,
  togglePaymentLink,
  toggleAdminLink,
  toggleAccountLink,
  toggleTransLink,
  // toggleNostroLink,
  toggleSchemesLink,
  showtoggleNavLink,
  showtoggleBranchLink,
  showtoggleAdminLink,
  showtoggleAccountLink,
  showtogglePosLink,
  showtoggleTransLink,
  // showtoggleNostroLink,
  showtoggleSchemesLink,
  showtoggleMerchantLink,
  toggleMerchantLink,
  toggleNavBar,
  theme,
  setToggleSattleLink,
  toggleSattleLink,
  // toggleRetailPOSLink,
  // setToggleRetailPOSLink,
  // togglePswLink,
  // setTogglePswLink,
  toggleDynamicMenu,
  setToggleDynamicMenu,
}) => {
  const { setDate, setTime, setDateTime } = useDateTime();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    setSettings,
    setSystemSettings,
    setIsMobile,
    setCurrency,
    setCurrencyName,
  } = useDateTime();
  const width = window.innerWidth;
  const isMobile = width <= 768;
  const userData = localStorage.getItem("userData");
  if (userData) {
    let userPref = JSON.parse(userData).userPreferences;
    userPref?.map((pref) => {
      if (pref.prefKeys === "dateFormat") {
        setDate(pref.prefValues);
      } else if (pref.prefKeys === "timeFormat") {
        setTime(pref.prefValues);
      } else if (pref.prefKeys === "dateTimeFormat") {
        setDateTime(pref.prefValues);
      }
    });
  }
  useEffect(() => {
    axiosInstance
      .get("/common?types=general_settings")
      .then((res) => {
        if (res.data !== null) setSettings(res.data.data);
        const systemSettings = res.data.data.find(
          (setting) => setting.id === "systemSettings"
        );
        if (systemSettings) setSystemSettings(systemSettings);
        const currencySetting = res.data.data.find(
          (setting) => setting.id === "currencySettings"
        );
        if (currencySetting) {
          let symbol = currencySetting.subType.find(
            (currency) => currency.id === "currencySymbol"
          );
          let code = currencySetting.subType.find(
            (currency) =>
              currency.id === "currencyCode" && currency.selectedValue
          );
          setCurrency(symbol.selectedValue);
          setCurrencyName(code.selectedValue);
        }
        setIsMobile(isMobile);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);
  // const history = useHistory();
  const [accountsList, setAccountsList] = useState([]);
  const [dynamicMenus, setDynamicMenus] = useState([]);

  const getAccountsMenu = () => {
    axiosInstance
      .get("/accounts/account-types?typeIndicator=gl")
      .then((response) => {
        setAccountsList(response?.data);
      });
  };

  const getReconMenu = async () => {
    try {
      let response = await axiosInstance.get("/recon/recon-menus");
      if (response.message.status !== "200") {
        setDynamicMenus([]);
      } else {
        setDynamicMenus(response.data);
      }
    } catch (err) {
      console.log("err in getting nostro sidebar menu..", err);
    }
  };

  useEffect(() => {
    getAccountsMenu();
    getReconMenu();
  }, []);

  return (
    <div>
      {navBar ? (
        <div className="backdrop" onClick={() => showNavBar(!navBar)}></div>
      ) : null}
      <SidebarStyle
        className={`sidebar fancy-scroll ${
          navBar === true ? "d-block" : "d-none"
        } d-md-block`}
      >
        <div className="d-flex align-items-center brand-name-wrapper">
          <img
            src={toggleNavBar ? logo : theme === "dark" ? logoDark : logoNormal}
            alt="logo"
            style={{
              width: toggleNavBar ? "50px" : "180px",
              marginLeft: toggleNavBar ? "0px" : "20px",
            }}
          />
        </div>
        <div>
          <ul>
            <ShowForPermission permission="mnu_sum_dash">
              <li className="parent-li">
                <NavLink
                  id="mnu_sum_dash"
                  to="/"
                  className={`d-flex align-items-center ${
                    location.pathname === "/" ? "selectedItem" : ""
                  }`}
                  onClick={isMobile ? () => showNavBar(!navBar) : null}
                >
                  <img className="align-baseline" src={dashboard} alt="dash" />
                  {/* <img className="align-baseline" src={dashboardActive} alt="dash" /> */}
                  <span className="NavLink-text">
                    {t("SIDEBAR.summary_dashboard")}
                  </span>
                </NavLink>
              </li>
            </ShowForPermission>

            <ShowForPermission permission="mnu_work_queue">
              <li className="parent-li">
                <NavLink
                  to="/workqueue"
                  className={`d-flex align-items-center ${
                    location.pathname === "/workqueue" ? "selectedItem" : ""
                  }`}
                  onClick={isMobile ? () => showNavBar(!navBar) : null}
                >
                  <img
                    className="align-baseline"
                    src={workqueue}
                    alt="workqueue1"
                  />
                  {/* <img className="align-baseline" src={dashboardActive} alt="dash" /> */}
                  <span className="NavLink-text">
                    {" "}
                    {t("SIDEBAR.work_queue")}
                  </span>
                </NavLink>
              </li>
            </ShowForPermission>
            <div>
              <ShowForPermission permission="mnu_atm">
                <li className="parent-li">
                  {/* eslint-disable-next-line */}
                  <a
                    id="atm_mnu"
                    onClick={() => showtoggleNavLink(!toggleNavLink)}
                    // to="/atm-drill-down"
                    className={`d-flex align-items-center justify-content-between ${
                      location.pathname === "/atm-drill-down" ||
                      location.pathname === "/device-view"
                        ? "selectedItem"
                        : ""
                    }`}
                  >
                    <div className="flex-row d-flex">
                      <img
                        className="align-baseline"
                        src={atmLight}
                        alt="atm"
                      />
                      <span className="NavLink-text"> {t("SIDEBAR.atm")}</span>
                    </div>
                    <img
                      className="align-baseline"
                      style={{ width: "26px", height: "26px" }}
                      src={toggleNavLink ? chevUp : chevDown}
                      alt={toggleNavLink ? "chev-up" : "chev-down"}
                    />
                  </a>
                </li>
              </ShowForPermission>
              {toggleNavLink && (
                <ul>
                  <ShowForPermission permission="mnu_atm_dash">
                    <li className="child-li">
                      <NavLink
                        id="atm_dash_mnu"
                        to="/atm-drill-down"
                        className={`d-flex align-items-center ${
                          location.pathname === "/atm-drill-down"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Dashboard</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_atm_list">
                    <li className="child-li">
                      <NavLink
                        id="atm_list_mnu"
                        to="/device-view"
                        className={`d-flex align-items-center ${
                          location.pathname === "/device-view"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">ATM Balancing</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>

                  <ShowForPermission permission="atm_cash_replenishment">
                    <li className="child-li">
                      <NavLink
                        id="atm_cash_replenishment_mnu"
                        to="/cash-replenishment"
                        className={`d-flex align-items-center ${
                          location.pathname === "/cash-replenishment"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Cash Replenishment</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                </ul>
              )}
            </div>
            <div>
              <ShowForPermission permission="mnu_merchant">
                <li className="parent-li">
                  {/* eslint-disable-next-line */}
                  <a
                    onClick={() => showtoggleMerchantLink(!toggleMerchantLink)}
                    // to="/atm-drill-down"
                    className={`d-flex align-items-center justify-content-between ${
                      toggleNavLink ? "active" : ""
                    } ${
                      location.pathname === "/merchant-dashboard" ||
                      location.pathname === "/merchant-transactions"
                        ? "selectedItem"
                        : ""
                    }`}
                  >
                    <div className="flex-row d-flex">
                      <img
                        className="align-baseline"
                        src={posLight}
                        alt="pos"
                      />
                      <span className="NavLink-text">Merchant</span>
                    </div>
                    <img
                      className="align-baseline"
                      style={{ width: "26px", height: "26px" }}
                      src={toggleMerchantLink ? chevUp : chevDown}
                      alt={toggleMerchantLink ? "chev-up" : "chev-down"}
                    />
                  </a>
                </li>
              </ShowForPermission>
              {toggleMerchantLink && (
                <ul>
                  <ShowForPermission permission="mnu_merchant_dash">
                    <li className="child-li">
                      {/* eslint-disable-next-line */}
                      <NavLink
                        to="/merchant-dashboard"
                        className={`d-flex align-items-center ${
                          location.pathname === "/merchant-dashboard"
                            ? "selectedItem"
                            : ""
                        }`}
                      >
                        <span className="NavLink-text">Summary Dashboard</span>
                      </NavLink>
                    </li>
                    <li className="child-li">
                      <NavLink
                        to="/merchant-transactions"
                        className={`d-flex align-items-center ${
                          location.pathname === "/merchant-transaction"
                            ? "selectedItem"
                            : ""
                        }`}
                      >
                        <span className="NavLink-text">Transactions</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                </ul>
              )}
              <ShowForPermission permission="mnu_pos">
                <li className="parent-li">
                  {/* eslint-disable-next-line */}
                  <a
                    onClick={() => showtogglePosLink(!togglePosLink)}
                    // to="/atm-drill-down"
                    className={`d-flex align-items-center justify-content-between ${
                      location.pathname === "/pos-dashboard" ||
                      location.pathname === "/pos-detail-view"
                        ? "selectedItem"
                        : ""
                    }`}
                  >
                    <div className="flex-row d-flex">
                      <img
                        className="align-baseline"
                        src={posLight}
                        alt="pos"
                      />
                      <span className="NavLink-text">POS</span>
                    </div>
                    <img
                      className="align-baseline"
                      style={{ width: "26px", height: "26px" }}
                      src={togglePosLink ? chevUp : chevDown}
                      alt={togglePosLink ? "chev-up" : "chev-down"}
                    />
                  </a>
                </li>
              </ShowForPermission>
              {togglePosLink && (
                <ul>
                  <ShowForPermission permission="mnu_pos_dash">
                    <li className="child-li">
                      {/* eslint-disable-next-line */}
                      <NavLink
                        to="/pos-dashboard"
                        className={`d-flex align-items-center ${
                          location.pathname === "/pos-dashboard"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Dashboard</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_mrchnt_list">
                    <li className="child-li">
                      <NavLink
                        to="/pos-detail-view"
                        className={`d-flex align-items-center ${
                          location.pathname === "/pos-detail-view"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Merchant List</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                </ul>
              )}

              <ShowForPermission permission="mnu_scheme">
                <li className="parent-li">
                  {/* eslint-disable-next-line */}
                  <a
                    id="scheme_mnu"
                    onClick={() => showtoggleSchemesLink(!toggleSchemesLink)}
                    // to="/atm-drill-down"
                    className={`d-flex align-items-center justify-content-between ${
                      location.pathname === "/schemes-1link"
                        ? "selectedItem"
                        : ""
                    }`}
                  >
                    <div className="flex-row d-flex">
                      <img
                        className="align-baseline"
                        src={posLight}
                        alt="pos"
                      />
                      <span className="NavLink-text">Scheme & Networks</span>
                    </div>
                    <img
                      className="align-baseline"
                      style={{ width: "26px", height: "26px" }}
                      src={toggleSchemesLink ? chevUp : chevDown}
                      alt={toggleSchemesLink ? "chev-up" : "chev-down"}
                    />
                  </a>
                </li>
              </ShowForPermission>

              {toggleSchemesLink && (
                <ul>
                  <ShowForPermission permission="sub_menu_scheme_1_link">
                    <li className="child-li">
                      <NavLink
                        id="scheme_1_link_sub_menu"
                        to="/schemes-1link"
                        className={`d-flex align-items-center ${
                          location.pathname === "/schemes-1link"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">1-Link</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="sub_menu_scheme_raast">
                    <li className="child-li">
                      <NavLink
                        id="scheme_raast_sub_menu"
                        to="/schemes-raast"
                        className={`d-flex align-items-center ${
                          location.pathname === "/schemes-raast"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">RAAST</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="sub_menu_scheme_china_union_pay">
                    <li className="child-li">
                      <NavLink
                        id="scheme_china_union_pay_sub_menu"
                        to="/schemes-upi"
                        className={`d-flex align-items-center ${
                          location.pathname === "/schemes-upi"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">UPI</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>

                  <ShowForPermission permission="sub_menu_scheme_uae_switch">
                    <li className="child-li">
                      <NavLink
                        id="scheme_uae_switch_sub_menu"
                        to="/schemes-uae-switch"
                        className={`d-flex align-items-center ${
                          location.pathname === "/schemes-uae-switch"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">UAE Swtich</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>

                  <ShowForPermission permission="sub_menu_scheme_visa">
                    <li className="child-li">
                      <NavLink
                        id="scheme_visa_sub_menu"
                        to="/schemes-visa"
                        className={`d-flex align-items-center ${
                          location.pathname === "/schemes-visa"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">VISA</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>

                  <ShowForPermission permission="sub_menu_scheme_mastercard">
                    <li className="child-li">
                      <NavLink
                        id="scheme_mastercard_sub_menu"
                        to="/schemes-master-card"
                        className={`d-flex align-items-center ${
                          location.pathname === "/schemes-master-card"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Mastercard</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                </ul>
              )}
            </div>
            <div>
              <ShowForPermission permission="mnu_branch">
                <li className="parent-li">
                  {/* eslint-disable-next-line */}
                  <a
                    id="branch_mnu"
                    onClick={() => showtoggleBranchLink(!toggleBranchLink)}
                    // to="/atm-drill-down"
                    className={`d-flex align-items-center justify-content-between ${
                      toggleBranchLink ? "active" : ""
                    } ${
                      location.pathname === "/branch-dashboard" ||
                      location.pathname === "/branch-list"
                        ? "selectedItem"
                        : ""
                    }`}
                  >
                    <div className="flex-row d-flex">
                      <img
                        className="align-baseline"
                        src={branchIcon}
                        alt="pos"
                      />
                      <span className="NavLink-text">Branch</span>
                    </div>
                    <img
                      className="align-baseline"
                      style={{ width: "26px", height: "26px" }}
                      src={toggleBranchLink ? chevUp : chevDown}
                      alt={toggleBranchLink ? "chev-up" : "chev-down"}
                    />
                  </a>
                </li>
              </ShowForPermission>
              {toggleBranchLink && (
                <ul>
                  <ShowForPermission permission="mnu_branch_dash">
                    <li className="child-li">
                      {/* eslint-disable-next-line */}
                      <NavLink
                        id="branch_dashboard_menu"
                        to="/branch-dashboard"
                        className={`d-flex align-items-center ${
                          location.pathname === "/branch-dashboard"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Dashboard</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_branch_list">
                    <li className="child-li">
                      {/* eslint-disable-next-line */}
                      <NavLink
                        id="branch_list_menu"
                        to="/branch-list"
                        className={`d-flex align-items-center ${
                          location.pathname === "/branch-list"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Branch List</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                </ul>
              )}
            </div>
            <div>
              <ShowForPermission permission="mnu_digital">
                <li className="parent-li">
                  <NavLink
                    id="digital_mnu"
                    to="/digital-platform"
                    className={`d-flex align-items-center ${
                      location.pathname === "/digital-platform"
                        ? "selectedItem"
                        : ""
                    }`}
                    onClick={isMobile ? () => showNavBar(!navBar) : null}
                  >
                    <img
                      className="align-baseline"
                      src={paymentIcon}
                      alt="dash"
                    />
                    <span className="NavLink-text">Digital Platform</span>
                  </NavLink>
                </li>
              </ShowForPermission>
              {togglePaymentLink && (
                <ul>
                  <ShowForPermission permission="mnu_digital_dash">
                    <li className="child-li">
                      {/* eslint-disable-next-line */}
                      <a
                        id="digital_dashboard_menu"
                        // to="/atm-drill-down"
                        className="d-flex align-items-center"
                      >
                        <span className="NavLink-text">Dashboard</span>
                      </a>
                    </li>
                  </ShowForPermission>
                </ul>
              )}
            </div>
            <div>
              <ShowForPermission permission="menu_settlement_summary">
                <li className="parent-li">
                  {/* eslint-disable-next-line */}
                  <a
                    id="settlement_summary_menu"
                    onClick={() => setToggleSattleLink(!toggleSattleLink)}
                    // to="/atm-drill-down"
                    className={`d-flex align-items-center justify-content-between ${
                      location.pathname === "/1link-atm" ? "selectedItem" : ""
                    }`}
                  >
                    <div className="flex-row d-flex">
                      <img
                        className="align-baseline"
                        src={transactionIcon}
                        alt="atm"
                      />
                      <span className="NavLink-text">
                        {" "}
                        {t("SIDEBAR.settlement_summary")}
                      </span>
                    </div>
                    <img
                      className="align-baseline"
                      style={{ width: "26px", height: "26px" }}
                      src={toggleSattleLink ? chevUp : chevDown}
                      alt={toggleSattleLink ? "chev-up" : "chev-down"}
                    />
                  </a>
                </li>
              </ShowForPermission>
              {toggleSattleLink && (
                <ul>
                  <ShowForPermission permission="sub_menu_1link_settlement_summary">
                    <li className="child-li">
                      <NavLink
                        id="1link_settlement_summary_menu"
                        to="/1link-atm"
                        className={`d-flex align-items-center ${
                          location.pathname === "/1link-atm"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">1LINK</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>

                  <ShowForPermission permission="sub_menu_uae_switch_settlement_summary">
                    <li className="child-li">
                      <NavLink
                        id="uae_switch_settlement_summary_menu"
                        to="/settlement-summary-uae-switch"
                        className={`d-flex align-items-center ${
                          location.pathname === "/settlement-summary-uae-switch"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">UAE Switch</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>

                  <ShowForPermission permission="sub_menu_visa_settlement_summary">
                    <li className="child-li">
                      <NavLink
                        id="visa_settlement_summary_menu"
                        to="/settlement-summary-visa"
                        className={`d-flex align-items-center ${
                          location.pathname === "/settlement-summary-visa"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">VISA</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>

                  <ShowForPermission permission="sub_menu_mastercard_settlement_summary">
                    <li className="child-li">
                      <NavLink
                        id="mastercard_settlement_summary_menu"
                        to="/settlement-summary-master-card"
                        className={`d-flex align-items-center ${
                          location.pathname ===
                          "/settlement-summary-master-card"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Mastercard</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                </ul>
              )}
            </div>
            <div>
              <ShowForPermission permission="mnu_txns">
                <li className="parent-li">
                  {/* eslint-disable-next-line */}
                  <a
                    id="transactions_menu"
                    onClick={() => showtoggleTransLink(!toggleTransLink)}
                    // to="/atm-drill-down"
                    className={`d-flex align-items-center justify-content-between ${
                      location.pathname.includes("/transaction-log") ||
                      location.pathname.includes("matching")
                        ? "selectedItem"
                        : ""
                    }`}
                  >
                    <div className="flex-row d-flex">
                      <img
                        className="align-baseline"
                        src={transactionIcon}
                        alt="atm"
                      />
                      <span className="NavLink-text">
                        {" "}
                        {t("SIDEBAR.transactions")}
                      </span>
                    </div>
                    <img
                      className="align-baseline"
                      style={{ width: "26px", height: "26px" }}
                      src={toggleTransLink ? chevUp : chevDown}
                      alt={toggleTransLink ? "chev-up" : "chev-down"}
                    />
                  </a>
                </li>
              </ShowForPermission>
              {toggleTransLink && (
                <ul>
                  <ShowForPermission permission="mnu_txns_log">
                    <li className="child-li">
                      <NavLink
                        id="transaction_log_menu"
                        to="/transaction-log"
                        className={`d-flex align-items-center ${
                          location.pathname.includes("/transaction-log")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Transaction Log</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_txns_match">
                    <li className="child-li">
                      <NavLink
                        id="transaction_match_menu"
                        to="/matching-pairs"
                        className={`d-flex align-items-center ${
                          location.pathname.includes("matching")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Matching</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                </ul>
              )}
            </div>

            {/* Dynamic menus */}
            {dynamicMenus &&
              dynamicMenus.map((item, index) => (
                <div key={item.groupName}>
                  <ShowForPermission permission={item?.groupPermission?.id}>
                    <li className="parent-li">
                      <a
                        id={`${item?.groupName?.replace(/\s+/g, "-")}_menu`}
                        data-toggle="collapse"
                        data-target={`#submenu-${item?.groupName?.replace(
                          /\s+/g,
                          "-"
                        )}`}
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname === "/nostro-dynamic-acccounts"
                            ? "selectedItem"
                            : ""
                        }`}
                      >
                        <div className="flex-row d-flex">
                          <img
                            className="align-baseline"
                            src={transactionIcon}
                            alt="atm"
                          />
                          <span className="NavLink-text">{item.groupName}</span>
                        </div>
                        <img
                          className="align-baseline"
                          style={{ width: "26px", height: "26px" }}
                          src={toggleDynamicMenu === index ? chevUp : chevDown}
                          alt={
                            toggleDynamicMenu === index
                              ? "chev-up"
                              : "chev-down"
                          }
                        />
                      </a>
                    </li>
                  </ShowForPermission>

                  <div
                    id={`submenu-${item?.groupName?.replace(/\s+/g, "-")}`}
                    className={`collapse ${
                      toggleDynamicMenu === index ? "show" : ""
                    }`}
                  >
                    <ul>
                      {item &&
                        item?.recons?.map((subItem, subIndex) => (
                          <ShowForPermission
                            permission={
                              subItem?.permission?.find(
                                (item) => item.type === "menu"
                              )?.id
                            }
                          >
                            <li className="child-li" key={subItem?.reconId}>
                              <NavLink
                                id={`${subItem?.reconName?.replace(
                                  /\s+/g,
                                  "-"
                                )}_menu`}
                                to={{
                                  pathname: `/dynamic-recon`,
                                  state: { subItem: subItem, item: item }, // Pass the entire item object in the state
                                }}
                                className={`d-flex align-items-center ${
                                  location?.state?.subItem?.reconName ===
                                  subItem.reconName
                                    ? "selectedItem"
                                    : ""
                                }
                    `}
                                exact
                                onClick={
                                  isMobile ? () => showNavBar(!navBar) : null
                                }
                              >
                                <span className="NavLink-text">
                                  {subItem.reconName}
                                </span>
                              </NavLink>
                            </li>
                          </ShowForPermission>
                        ))}
                    </ul>
                  </div>
                </div>
              ))}

            <ShowForPermission permission="mnu_ops">
              <li className="parent-li">
                <NavLink
                  id="operations_menu"
                  to="/operations"
                  className={`d-flex align-items-center ${
                    location.pathname === "/operations" ? "selectedItem" : ""
                  }`}
                  onClick={isMobile ? () => showNavBar(!navBar) : null}
                >
                  <img className="align-baseline" src={Operations} alt="dash" />
                  <span className="NavLink-text">
                    {" "}
                    {t("SIDEBAR.operations")}
                  </span>
                </NavLink>
              </li>
            </ShowForPermission>
            <div>
              <ShowForPermission permission="mnu_acc">
                <li className="parent-li">
                  {/* eslint-disable-next-line */}
                  <a
                    id="account_mnu"
                    onClick={() => showtoggleAccountLink(!toggleAccountLink)}
                    className={`d-flex align-items-center justify-content-between ${
                      location.pathname === "/Accounts" ? "selectedItem" : ""
                    }`}
                  >
                    <div className="d-flex">
                      <img
                        className="align-baseline"
                        src={accounts}
                        alt="dash"
                      />
                      <span className="NavLink-text">
                        {" "}
                        {t("SIDEBAR.accounts")}
                      </span>
                    </div>
                    <img
                      className="align-baseline"
                      style={{ width: "26px", height: "26px" }}
                      src={toggleAccountLink ? chevUp : chevDown}
                      alt={toggleAccountLink ? "chev-up" : "chev-down"}
                    />
                  </a>
                </li>
              </ShowForPermission>
              {toggleAccountLink && (
                <ShowForPermission permission="mnu_sub_acc">
                  <ul>
                    {accountsList &&
                      accountsList.map((account, index) => (
                        <li key={index} className="child-li">
                          <NavLink
                            id={account?.accountType}
                            to={`/Accounts?typeId=${account?.accountId}`}
                            className={`d-flex align-items-center ${
                              location.pathname === "/Accounts" &&
                              location.search.includes(account.accountId)
                                ? "selectedItem"
                                : ""
                            }`}
                            exact
                            //
                            onClick={
                              isMobile ? () => showNavBar(!navBar) : null
                            }
                          >
                            <span className="NavLink-text">
                              {account.accountType}
                            </span>
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </ShowForPermission>
              )}
            </div>
            <div>
              <ShowForPermission permission="mnu_admin">
                <li className="parent-li">
                  {/* eslint-disable-next-line */}
                  <a
                    id="admin_mnu"
                    onClick={() => showtoggleAdminLink(!toggleAdminLink)}
                    // to="/atm-drill-down"
                    className={`d-flex align-items-center justify-content-between ${
                      toggleAdminLink ? "active" : ""
                    } ${
                      location.pathname.includes("atmlist") ||
                      location.pathname.includes("atm-management") ||
                      location.pathname.includes("account-management") ||
                      location.pathname.includes("recon-account-management") ||
                      location.pathname.includes("branch-management") ||
                      location.pathname.includes("/region-management") ||
                      location.pathname.includes("/institution-management") ||
                      location.pathname.includes("/user-profile-management") ||
                      location.pathname.includes("/cit-management") ||
                      location.pathname.includes("/team-management") ||
                      location.pathname.includes("/workflow-manager") ||
                      location.pathname.includes("/schedules") ||
                      location.pathname.includes("/interface-configuration") ||
                      location.pathname.includes("/audit-log") ||
                      location.pathname.includes("/settings") ||
                      location.pathname.includes("/nostro-accounts")
                        ? "selectedItem"
                        : ""
                    }`}
                  >
                    <div className="flex-row d-flex">
                      <img
                        className="align-baseline"
                        src={adminIcon}
                        alt="atm"
                      />
                      <span className="NavLink-text">
                        {" "}
                        {t("SIDEBAR.administration")}
                      </span>
                    </div>
                    <img
                      className="align-baseline"
                      style={{ width: "26px", height: "26px" }}
                      src={toggleAdminLink ? chevUp : chevDown}
                      alt={toggleAdminLink ? "chev-up" : "chev-down"}
                    />
                  </a>
                </li>
              </ShowForPermission>
              {toggleAdminLink && (
                <ul>
                  <ShowForPermission permission="mnu_account_mgmt">
                    <li className="child-li">
                      <NavLink
                        id="acc_mgmt_mnu"
                        to="/account-management"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes("account-management")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">Account Management</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_recon_account_mgmt">
                    <li className="child-li">
                      <NavLink
                        id="recon_acc_mgmt_mnu"
                        to="/recon-accounts-management"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes(
                            "recon-accounts-management"
                          )
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">
                          Recon Account Management
                        </span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_atm_mgmt">
                    <li className="child-li">
                      <NavLink
                        id="atm-mgmt-mnu"
                        to="/atmlist"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes("atmlist") ||
                          location.pathname.includes("atm-management")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">ATM Management</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_branch_mgmt">
                    <li className="child-li">
                      <NavLink
                        id="branch_mgmt-mnu"
                        to="/branch-management"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes("branch-management")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">Branch Management</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>

                  <ShowForPermission permission="mnu_region_mgmt">
                    <li className="child-li">
                      <NavLink
                        id="region_mgmt-mnu"
                        to="/region-management"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes("/region-management")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">Region Management</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_inst_mgmt">
                    <li className="child-li">
                      <NavLink
                        id="inst_mgmt_mnu"
                        to="/institution-management"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes("/institution-management")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">
                          Institution Management
                        </span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_user_mgmt">
                    <li className="child-li">
                      <NavLink
                        id="user_mgmt_mnu"
                        to="/user-profile-management"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes("/user-profile-management")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">User Management</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="sub_menu_admin_role_management">
                    <li className="child-li">
                      <NavLink
                        id="role_mgmt_mnu"
                        to="/role-management"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes("/role-management")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">Role Management</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_cit_mgmt">
                    <li className="child-li">
                      <NavLink
                        id="cit_mgmt_mnu"
                        to="/cit-management"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes("/cit-management")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">CIT Management</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>

                  <ShowForPermission permission="sub_menu_admin_team_management">
                    <li className="child-li">
                      <NavLink
                        id="team_mgmt_mnu"
                        to="/team-management"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes("/team-management")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">Team Management</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_workflow_mgr">
                    <li className="child-li">
                      <NavLink
                        id="workflow_mgr_mnu"
                        to="/workflow-manager"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname.includes("/workflow-manager")
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Workflow Manager</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_workflow_mgr">
                    <li className="child-li">
                      <NavLink
                        id="schedules_mnu"
                        to="/schedules"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname === "/schedules"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">Schedules</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_interface_mgr">
                    <li className="child-li">
                      <NavLink
                        id="interface_mgr_mnu"
                        to="/interface-configuration"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname === "/interface-configuration"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">
                          Interface Configuration
                        </span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_audit_log">
                    <li className="child-li">
                      <NavLink
                        id="audit_log_mnu"
                        to="/audit-log"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname === "/audit-log"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                        //
                      >
                        <span className="NavLink-text">Audit Log</span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                  <ShowForPermission permission="mnu_settings">
                    <li className="child-li">
                      <NavLink
                        id="settings_mnu"
                        to="/settings"
                        className={`d-flex align-items-center justify-content-between ${
                          location.pathname === "/settings"
                            ? "selectedItem"
                            : ""
                        }`}
                        onClick={isMobile ? () => showNavBar(!navBar) : null}
                      >
                        <span className="NavLink-text">
                          {" "}
                          {t("SIDEBAR.settings")}
                        </span>
                      </NavLink>
                    </li>
                  </ShowForPermission>
                </ul>
              )}
            </div>
            <ShowForPermission permission="mnu_event_log">
              <li className="parent-li" title="EventLog">
                <NavLink
                  id="event_log_mnu"
                  to="/event-log"
                  className={`d-flex ${
                    location.pathname === "/event-log" ? "selectedItem" : ""
                  }`}
                  onClick={isMobile ? () => showNavBar(!navBar) : null}
                >
                  <img className="align-baseline" src={log} alt="log" />
                  <span className="NavLink-text">
                    {" "}
                    {t("SIDEBAR.event_log")}
                  </span>
                </NavLink>
              </li>
            </ShowForPermission>
            <ShowForPermission permission="mnu_reports">
              <li className="parent-li">
                <NavLink
                  id="reports_mnu"
                  to="/reports"
                  className={`d-flex ${
                    location.pathname === "/reports" ? "selectedItem" : ""
                  }`}
                  onClick={isMobile ? () => showNavBar(!navBar) : null}
                >
                  <img
                    className="align-baseline"
                    src={reportsIcon}
                    alt="reports"
                  />
                  <span className="NavLink-text"> Reports</span>
                </NavLink>
              </li>
            </ShowForPermission>
          </ul>
        </div>
      </SidebarStyle>
    </div>
  );
};

export default Sidebar;
